.bottomOrder {
    /*bottom: 5%;*/
    /*position: absolute;*/
    /*margin: 0 62px 16px 62px;*/
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.bottomOrder span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #0AB06A;
}

.bottomOrder span:last-child {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #696969;
}

.bottomOrder .upToFreeDelivery {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
}

.bottomOrder .upToFreeDelivery span {
    font-weight: bold;
}

.bottomOrder a {
    text-align: center;
    margin: 57px 0 10px 0;
}

.bottomOrder .blockButton a:last-child {
    width: 191px;
    background-color: #000000;
    color: #FFFFFF;
}

.bottomOrder .blockButton a:last-child:hover {
    width: 191px;
    background-color: #FFFFFF;
    color: #000000;
}

.totalPrice {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
}

.totalPrice span {
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #000000;
}

.totalPrice span:first-child {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
}

.emptyCart {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A6A6A6;
    margin-bottom: 15px;
}

/*.scrollItem {*/
/*    background:*/
/*            linear-gradient(#FFFFFF 33%, rgba(211,61,186, 0)),*/
/*            linear-gradient(rgba(211,61,186, 0), #FFFFFF 66%) 0 100%,*/
/*            radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),*/
/*            radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;*/
/*    background-repeat: no-repeat;*/
/*    background-attachment: local, local, scroll, scroll;*/
/*    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;*/
/*}*/

.scrollItem::-webkit-scrollbar {
    display: none;
}

.scrollItem {
  position: relative;
  z-index: 1;
  overflow: auto;
  width: 100%;
  max-height: 500px;
  margin: 50px auto;
  background: #FFF no-repeat;
  background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-position: 0 0, 0 100%;
  background-size: 100% 14px;
}

.scrollItem:before,
.scrollItem:after {
  content: "";
  position: relative;
  z-index: -1;
  display: block;
  height: 30px;
  margin: 0 0 -30px;
  background: -webkit-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
  background: linear-gradient(to bottom, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
}

.scrollItem:after {
  margin: -30px 0 0;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
}

@media (max-width: 768px) {
  .scrollItem {
    max-height: 300px;
  }
}
